import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { helmet_url } from "../../Helpers/request";
import { Helmet } from "react-helmet";
import BannerSlider from "./Bannerslider";
import Featured from "./featured";
import Collection1 from "./Collection1";
import StateJewelry from "./Statejewelry";
import Collection2 from "./Collection2";
import IndianJewelry from "./Indianjewelry";
import Philanthropy from "./Philanthropy";
import Testimonial from "./Testimonial";
import Newsletter from "./Newsletter";
import Insta from "./insta";
import Store from "./Store";
import { Modal } from "react-bootstrap";
import MakeAnAppointment from '../Pages/make-an-appointment';
import { useSelector } from "react-redux";

// import landingVideo from "../../Assets/images/landing_video.mp4";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)
  const articleStructuredData = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Malak Jewelers",
    logo: "https://malakjewelers.com/static/media/logo.c734bcd9.jpg",
    url: "https://malakjewelers.com/",
    telephone: "+1 (704) 341-1188",
    sameAs: [
      "https://www.facebook.com/MalakJewelers/",
      "https://www.youtube.com/user/malakjewelers",
      "https://www.instagram.com/malakjewelers/",
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: "8042 Providence Rd Ste 1000 ",
      addressLocality: "Charlotte, NC",
      postalCode: "28277",
      addressCountry: "USA",
    },
  };

  const [show, setShow] = useState(true);
  const videoStatus= localStorage.getItem('onLoadVideo');
  const handleClose = () => setShow(false);
  const closeVideo=()=>{
    handleClose();
    localStorage.setItem("onLoadVideo",false);
  }
  useEffect(() => {
    if(videoStatus==='false'){
      setShow(false);
    }else{
      setShow(true);
      
    }
    
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta
          name="description"
          content="Malak Jewelers is the best top-rated jewelry store in Charlotte, NC that features top bridal brands, fine jewelry, and gold jewelry. Come see us today!"
        ></meta>
 <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Malak Jewelers: The Destination for Jewelry in Charlotte, NC"
        />
       <meta name="description" content={metaDetails.description} />
        <meta property="og:url" content={helmet_url} />
        <meta property="og:site_name" content="malakjewelers" />
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      </Helmet>
      <BannerSlider />
      <Featured />
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      <Collection1 />
      <StateJewelry />
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      <Collection2 />
      <IndianJewelry />
      <Store />
      <Philanthropy />
      <Testimonial />
      <Insta />
      <Newsletter />

      {/* <Modal show={show} size="md" backdrop="static" onHide={handleClose} className="vidModal" centered>
        <Modal.Body>
          <div className="closeVid" onClick={()=>closeVideo()}>
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              data-testid="ClearIcon"
            >
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </div>
          <div className="videoWrapper">
          <MakeAnAppointment modalView={true}/>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default Home;
