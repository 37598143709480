import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const BannerSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const banner = localStorage.getItem('mj-bannerdata') ? JSON.parse(localStorage.getItem('mj-bannerdata')) : [];
  return (
    <>{banner?.length ?
      <Slider className="mj_slider_img" {...settings}>
        {banner?.map((val, index) => (
          <div>
            {isMobileOnly ? (
              <Link to={val.btn_link}><img src={val.mobile_image} alt={val.alt_tag} /></Link>
            ) : (
              <Link to={val.btn_link}><img src={val.image} alt={val.alt_tag} /></Link>
            )}
          </div>
        ))}
      </Slider> :
      <div >
        {isMobileOnly ? (
          <Skeleton style={{ transform: 'unset', marginTop: '10px' }} variant="text" animation="wave" height={285} />
        ) :
          <Skeleton style={{ transform: 'unset', marginTop: '10px' }} variant="text" animation="wave" height={580} />
        }
      </div>
    }
    </>
  );
}

export default BannerSlider;