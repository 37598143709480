import React from 'react';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import collection_img1 from "../../Assets/images/home/wed.webp";
import collection_img2 from "../../Assets/images/home/ear.webp";
import collection_img3 from "../../Assets/images/home/fine.webp";
import { useHistory } from 'react-router-dom';

const Collection1 = () => {
  const history = useHistory();
  return (
    <>
      <Container className="mj_newcontainer mt-5" fluid>
        <Row className="m-0 w-100">
          <Col xs={12} sm={6} md={6} lg={4}>
            <div className="mj_collection mb-3">
              <NavLink onClick={() => history.push("/jewelry/wedding-bands")} className="d-block widget-inner">
                <img src={collection_img1} className="mx-auto" alt="Wedding Bands from Malak Jewelers in Charlotte, NC. Simple designs to complex designs." />
              </NavLink>
              <span onClick={() => history.push("/jewelry/wedding-bands")} className="d-inline-block btn-dark btn btn-md mj_main_btn2">
                Wedding Band Collection
              </span>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <div className="mj_collection mb-3">
              <NavLink onClick={() => history.push("/jewelry/earrings")} className="d-block widget-inner">
                <img src={collection_img2} className="mx-auto" alt="Diamond Stud Earrings from Malak Jewelers in Charlotte, NC. Variety of sizes and looks!" />
              </NavLink>
              <span onClick={() => history.push("/jewelry/earrings")} className="d-inline-block btn-dark btn btn-md mj_main_btn2">
                Diamond Earring Collection
              </span>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <div className="mj_collection mb-3">
              <NavLink onClick={() => history.push("/jewelry/fine-jewelry")} className="d-block widget-inner">
                <img src={collection_img3} className="mx-auto" alt="Shop Fine Jewelry from Malak Jewelers in Charlotte, NC. Finest jewelry that sparkles and shimmers." />
              </NavLink>
              <span onClick={() => history.push("/jewelry/fine-jewelry")} className="d-inline-block btn-dark btn btn-md mj_main_btn2">
                Fine Jewelry Collection
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Collection1;