import React from 'react';
import { Container, Row, Col, NavLink } from 'react-bootstrap';
import "../../Assets/css/home.css";
import feat1 from "../../Assets/images/home/engagement.webp";
import feat2 from "../../Assets/images/home/forever-mark.webp";
import feat4 from "../../Assets/images/home/financing.webp";
import { useHistory } from 'react-router-dom';
import { isMobileOnly, isTablet } from 'react-device-detect';
import custom_jewelry from "../../Assets/images/home/custom-jewelry.webp";
import custom_jewelry_mob from "../../Assets/images/home/custom-jewelry_mob.webp";

const Featured = () => {
    const history = useHistory();
    return (
        <>
            <Container className="mj_newcontainer mt-5" fluid>
                <Row className="m-0 w-100">
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <div className="mj_collection mb-3">
                            <NavLink onClick={() => history.push("/ringsettings")} className="d-block widget-inner">
                                <img src={feat1} className="mx-auto" alt="Engagement Rings from Malak Jewelers in Charlotte, NC. All rings vary from price, size, and liking that fits you best." />
                            </NavLink>
                            <span onClick={() => history.push("/ringsettings")} className="d-inline-block btn-dark btn btn-md mj_main_btn2">
                                Engagement Rings
                            </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <div className="mj_collection mb-3">
                            <NavLink onClick={() => history.push("/diamonds")}>
                                <img src={feat2} className="mx-auto" alt="Forever Mark" />
                            </NavLink>
                            <span onClick={() => history.push("/diamonds")} className="d-inline-block btn-dark btn btn-md mj_main_btn2">
                                Forevermark Collection
                            </span>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                        <div className="mj_collection mb-3">
                            <NavLink onClick={() => history.push("/financing")} className="d-block widget-inner">
                                <img src={feat4} className="mx-auto" alt="Financing options at Malak Jewelers in Charlotte, NC. Contact us today to discuss your options!" />
                            </NavLink>
                            <span onClick={() => history.push("/financing")} className="d-inline-block btn-dark btn btn-md mj_main_btn2">
                                Learn More
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="mj_estate_indian mb-5">
                <Container fluid className='p-0'>
                    <Row className='w-100 m-auto'>
                        <Col sm="12 p-0">
                            <div className="mj_estate_jewelry">
                                <NavLink onClick={() => history.push("/custom-design")} className='p-0'>
                                    {isMobileOnly ? <img src={custom_jewelry_mob} /> : isTablet ? <img src={custom_jewelry} /> :
                                        <img src={custom_jewelry} />
                                    }
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Featured;