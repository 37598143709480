import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../Assets/css/home.css";
import { Button } from '@material-ui/core';

const Insta = () => {
    return (
        <>
            <Container>
                <Row>
                    <div className="mj_news_content w-100">
                        <h2 className='text-center' onClick={() => window.open("https://www.instagram.com/malakjewelers/", "_blank")}>View our Instagram</h2>
                    </div>

                    <div className="mj_news_content text-center m-auto">
                        <p onClick={() => window.open("https://www.instagram.com/malakjewelers/", "_blank")}>Follow  <a href='https://www.instagram.com/malakjewelers/' target="_blank" className='text-dark'>@malakjewelers</a>  on Instagram to get inspired.</p>
                        <Button
                           onClick={() => window.open("https://www.instagram.com/malakjewelers/", "_blank")}
                            className="mj_how_btn"
                        >
                           Follow
                        </Button>
                    </div>
                </Row>
            </Container>
            <div class="elfsight-app-4cf9f664-267a-4939-8adf-9b07bb01ad0a"></div>
        </>
    )
}

export default Insta;