import React, { useState } from 'react';
import { Container, NavLink, Form, InputGroup, FormControl, Modal } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Button } from '@material-ui/core';
import { base_url, helmet_url, postHeader } from '../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Loader from '../../Components/Loader';

const Newsletter = () => {
  const history = useHistory();
  const [newsletteremail, setNewsletteremail] = useState("")
  const [loader,setLoader] = useState(false)
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true)
    const data = {
      email: newsletteremail
    }
    axios.post(base_url + '/common/subscribe_newsletter', data, {
      headers: postHeader
    })
      .then(res => {
        if (res.data.status == 1) {
          setLoader(false)
          setNewsletteremail('');
          return swal(res.data.message, "", "success");
        } else {
          setLoader(false)
          return swal(res.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }
  return (
    <>
      <div className="mj_newsletter">
        <Container>
          <div className="mj_news_content">
            <h2>sign up for our email list</h2>
            <div className="gs_newsletter_input_section">
              <Form className="gs_news_form w-100" onSubmit={newsletter}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Email Address..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    type="email"
                    value={newsletteremail}
                    required
                    onChange={(e) => setNewsletteremail(e.target.value)}
                  />
                  <Button variant="outline-secondary" type="submit" id="button-addon2">
                    Subscribe
                  </Button>
                </InputGroup>
              </Form>
            </div>
            <div className="gs_newsletter_content">
              <p>By signing up you confirm that you have read the <NavLink onClick={() => history.push("/privacy-policy")}>Privacy Policy</NavLink> and agree that your email will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p>
            </div>
          </div>
        </Container>
      </div>
      <Modal show={loader} >
        <Loader /> 
        </Modal>
    </>
  )
}

export default Newsletter;