import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { base_url} from "../Helpers/request";
import { metaDetails } from "../Reducer/metaReducer";

export default function MainRequest() {
    const routePath = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        MetaTag();
    }, [routePath]);


    //------------------- Meta tag ---------------------
    const MetaTag = () => {
        axios.get(`${base_url}/common/metatag?url=${window.location.pathname.slice(1)}`).then(res => {
            if (res.data.status == 1) {
                dispatch(metaDetails(res.data.data))
            }
        }).catch(error => console.log(error))
    }





    return null;

}

