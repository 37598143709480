import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Assets/css/custome.css';
import '../src/Assets/css/color.css';
import { base_url, isLogin, postHeader} from './Helpers/request';
import React,{ useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Skeleton } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { Suspense } from 'react';
import home from './Containers/Front/Home';
import { persistor } from './Store/store';
import MainRequest from './Helpers/mainRequest';
const MainRoute =  React.lazy(() => import('./mainRoute'));
const Header =  React.lazy(() => import('./Containers/Header/Header'));
const Footer =  React.lazy(() => import('./Containers/Footer/footer'));
const GotoTop =  React.lazy(() => import('./Containers/Pages/Static/GotoTop'));

const App = () => {
  const [menudata, setMenudata] = useState([]);
  const [menudataNew, setmenudataNew] = useState([]);

  useLayoutEffect(async() => {
    await clearlocal();
    if (localStorage.getItem("mj-session-id")?.length || isLogin) {
      console.log("session exist!")
    } else {
      axios.get(base_url + '/home/getsessionid')
        .then(res => {
          if (res.data.status == 1) {
            localStorage.setItem("mj-session-id", res.data.data?.session_id)
          } else {
            // toast.error(res.message,{autoClose: 3000});
            console.log("sessionid not generated")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    //menu
    const device_type = isDesktop ? 1 : 2 ;
    axios.get(base_url + '/home/menunew?device_type=' + device_type, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          setMenudata(response.data.data);
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          localStorage.setItem("mj-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const clearlocal = async () => {
    var localdate = localStorage.getItem('mj-localdate');
    var date = new Date().getDate();
    if (localdate != date) {
      await persistor.flush();
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("mj-localdate", date);
      window.location.reload()
    }
  }



    return (
      <>
        <BrowserRouter>
        <Suspense fallback={<><Skeltonheader/></>}>
        <MainRequest />
        </Suspense>
        <Suspense fallback={<><Skeltonheader/></>}>
          <Header menudata={menudata} menudataNew={menudataNew} />
          </Suspense>
          <Switch>
            <Route exact path="/" component={home} />
            <Suspense fallback={<><Skelton /></>}>
             <Route path='*' exact={true} component={MainRoute} />
             </Suspense>
          </Switch>
          <Suspense fallback={<><Skelton /></>}>
          <Footer />
          <GotoTop />
          </Suspense>
        </BrowserRouter>
      </>
    )
}
const Skelton = () => {
  return (<>
    <div className='sk' style={{margin: "15px 0px"}}>
      <Skeleton variant="text" animation="wave"  />
    </div>
  </>)
}
const Skeltonheader = () => {
  return (
    <div className='skh' style={{margin: "0px 0px 10px 0px"}}>
       <Skeleton variant="text" animation="wave"  height={75} />
    </div>)
}
export default App;