import React from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import estate_jewelry from "../../Assets/images/home/Estate-Jewelry-desk.webp";
import estate_jewelry_mob from "../../Assets/images/home/Estate-Jewelry-mobile.webp";
import { useHistory } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";

const StateJewelry = () => {
  const history = useHistory();
  return (
    <>
      {/* estate jewelry or indian jewelry */}
      <div className="mj_estate_indian mb-5">
        <Container fluid className="p-0">
          <Row className="w-100 m-auto">
            <Col sm="12 p-0">
              <div className="mj_estate_jewelry">
                <NavLink
                  onClick={() => history.push("/jewelry/estate-jewelry")}
                  className="p-0"
                >
                  {isMobileOnly ? (
                    <img src={estate_jewelry_mob} alt="Estate Jewelery Image" />
                  ) : isTablet ? (
                    <img src={estate_jewelry} alt="Estate Jewelery Image" />
                  ) : (
                    <img src={estate_jewelry} alt="Estate Jewelery Image" />
                  )}
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default StateJewelry;
