import React from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import indian_jewelry from "../../Assets/images/home/indian-j-banner.webp";
import indian_jewelry_mob from "../../Assets/images/home/indian-j-banner-mobile.webp";
import { useHistory } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";

const IndianJewelry = () => {
  const history = useHistory();
  return (
    
    <>
      <div className="mj_estate_indian">
        <Container fluid className="p-0">
          <Row className="w-100 m-auto">
            <Col sm="12 p-0">
              <div className="mj_estate_jewelry">
                <NavLink
                  onClick={() => history.push("/jewelry/indian-jewelry")}
                  className="p-0"
                >
                  {isMobileOnly ? (
                    <img src={indian_jewelry_mob} alt="Indian Jewelery Image" />
                  ) : isTablet ? (
                    <img src={indian_jewelry} alt="Indian Jewelery Image" />
                  ) : (
                    <img src={indian_jewelry} alt="Indian Jewelery Image" />
                  )}
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default IndianJewelry;
