import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../../Assets/css/home.css";
import store1 from '../../Assets/images/store/store1.webp';
import store2 from '../../Assets/images/store/store2.webp';
import store3 from '../../Assets/images/store/store4.webp';
import store4 from '../../Assets/images/store/store5.webp';
import store5 from '../../Assets/images/store/store6.webp';

const Store = () => {
  return (
    <>
        <Container className="mj_newcontainer mt-5" fluid>
            <Row className="m-0 w-100 justify-content-center">
                <div className="mj_collection1">
                    <h3> DISCOVER OUR SHOWROOM </h3>
                </div>    
            </Row>
            <Row className="m-0 w-100">
                <Col xs={12} sm={4} md={5} lg={6} className="mj_store_padding">
                    <div className="mj_collection1 mb-3">
                        <img src={store1} className="mx-auto" alt="Store Image Malak Jewelers" />
                    </div>
                </Col>
                <Col xs={12} sm={8} md={7} lg={6} className="mj_store_padding">
                    <Row className='m-auto w-100'>
                    <Col xs={12} sm={6} md={6} lg={6} className="mj_store_padding">
                        <div className="mj_collection2 mb-3">
                            <img src={store4} className="mx-auto" alt="Store Image Malak Jewelers" />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="mj_store_padding">
                        <div className="mj_collection2 mb-3">
                            <img src={store3} className="mx-auto" alt="Store Image Malak Jewelers" />
                        </div>
                    </Col>
                    </Row>
                    <Row className='m-auto w-100'>                    
                    <Col xs={12} sm={6} md={6} lg={6} className="mj_store_padding">
                        <div className="mj_collection2 mb-3">
                            <img src={store2} className="mx-auto" alt="Store Image Malak Jewelers" />
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="mj_store_padding">
                        <div className="mj_collection2 mb-3">
                            <img src={store5} className="mx-auto" alt="Store Image Malak Jewelers" />
                        </div>
                    </Col>
                    </Row>
                </Col>                
            </Row>
        </Container>
    </>
  )
}

export default Store;